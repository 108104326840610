import React, { useState } from "react";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { hl7Instance, ssoInstance, dbInstance } from "../../api/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import "./signup.scss";
import sliderbg from "../../assets/img/logo/freedom/background.png";
import poweredby from "../../assets/img/powered-by-aaneel.svg";
import memberIDCard from "../../assets/img/logo/freedom/pin-info.png";
import freedomLogo from "../../assets/img/logo/freedom/logo.svg";
import healthSunLogo from "../../assets/img/logo/healthsun/logo.svg";
import optimumLogo from "../../assets/img/logo/Optimum/logo.svg";
import optimumBg from "../../assets/img/logo/Optimum/optimum-bg-1.png";
import healthSunBg from "../../assets/img/logo/healthsun/healthsun_bg_1.png";
import demoLogo from "../../assets/img/logo/demo/demo-logo.svg";
import { useAuth } from "../../context/auth-context";
import { formatDate, InputformatDate } from "../../utils/Utils";
import { PreviousInsuranceApi } from "../../api/services/PrevInsuranceService";
const schema = yup.object().shape({
  FirstName: yup.string().required("First name is required"),
  LastName: yup.string().required("Last name is required"),
  Email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  Username: yup
    .string()
    .required("Username is required")
    .matches(
      /^[a-zA-Z0-9._-]+$/,
      "Username can only contain letters, numbers, underscores, and periods."
    ),

  Password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must not exceed 20 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain an uppercase letter, lowercase letter, a number, and a special character"
    )
    .test(
      "no-personal-info",
      "Password cannot include your first name or username.",
      function (value) {
        const { FirstName, Username } = this.parent;
        if (value) {
          return !value.includes(FirstName) && !value.includes(Username);
        }
        return true;
      }
    )
    .required("Password is required"),

  ConfirmPassword: yup.string().required("Confirm Password is required"),
  DOB: yup.date().required("Date of birth is required"),
  PIN: yup.string().required("PIN is required"),
  //OTP: yup.string().required("OTP is required"),
});

const getAccessToken = async () => {
  try {
    const params = new URLSearchParams({
      grant_type: "client_credentials",
      client_id: "FhirApiAdmin",
      client_secret: "ED3B48D2-6EDF-4293-B3E5-3386730E95B3",
    });

    const response = await hl7Instance.post(
      `/AadSmartOnFhirProxy/token`,
      params,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data.access_token;
  } catch (error) {
    console.error(
      "Error fetching the access token:",
      error.response ? error.response.data : error.message
    );
  }
};

function SignUpForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ID: uuidv4(),
    FirstName: "",
    LastName: "",
    Username: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
    DOB: "",
    PIN: "",
    MemberID: "",
    MedicareID: "",
    Clients: "",
  });
  const [alertMessages, setAlertMessages] = useState([]);
  const { userManager } = useAuth();
  const [loading, setLoading] = useState(false);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [otpDetails, setOtpDetails] = useState({
    otp: "",
    generatedTime: null,
  });
  const [newMemberId, setNewMemberID] = useState(null);
  const memberUrl = process.env.REACT_APP_SSO_hl7Instance;

  const fetchPatientRecord = async (identifier) => {
    setLoading(true);
    const token = await getAccessToken();
    try {
      const response = await hl7Instance.get(
        `/Patient?identifier=https://terminology.hl7.org/resources/variables/member-registration-pin|${identifier}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (
        response.data.entry &&
        response.data.entry.length > 0 &&
        response.data.entry[0].resource &&
        response.data.entry[0].resource.id
      ) {
        const memberId = response.data.entry[0].resource.id;
        setNewMemberID(response.data.entry[0].resource.id);
        // Now assign memberId to your state or wherever you want to store it
        setFormData((prevState) => ({
          ...prevState,
          MemberID: memberId,
        }));
      }
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error(
        "Error fetching the patient record:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const goToNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const goToPreviousStep = () => {
    if (currentStep === 3) {
      setEnteredOtp("");
      setErrors((prevErrors) => ({ ...prevErrors, OTP: undefined })); // Clearing OTP error
    }
    setCurrentStep((prevStep) => prevStep - 1);
  };
  const handleSendOtp = async () => {
    if (!formData.Email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Email: "Email is required",
      }));
      return;
    }

    setLoading(true);
    try {
      // Check if the email already exists
      const emailResponse = await PreviousInsuranceApi.checkEmailAlredyExist(
        formData.Email
      );
  
      if (emailResponse.status !== 204) {
        // If email already exists, show error and return early
        setAlertMessages([{ type: "error", text: "Email already exists." }]);
        setLoading(false);
        return;
      }

      // If the email does not exist, proceed to generate OTP
      await schema.validateAt("Email", formData); // Assuming you still want to validate the email before sending OTP
      const generatedOtp = Math.floor(
        100000 + Math.random() * 900000
      ).toString();
      setOtpDetails({ otp: generatedOtp, generatedTime: new Date() });

      // Create the email HTML template
      const htmlEmailTemplate = `<!DOCTYPE html>
        <html>
        <head>
            <style>
                .email-container {
                    font-family: Arial, sans-serif;
                    color: #333333;
                    max-width: 600px;
                    margin: auto;
                }
  
                .header {
                    background-color: #f2f2f2;
                    color: #000;
                    padding: 10px;
                    text-align: center;
                }
  
                .body {
                    padding: 20px;
                }
  
                .footer {
                    padding: 10px;
                    text-align: center;
                    background-color: #f2f2f2;
                    font-size: 12px;
                }
  
                .otp-code {
                    font-weight: bold;
                    font-size: 20px;
                }
            </style>
        </head>
        <body>
            <div class="email-container">
                <div class="header">
                    <h1>Your OTP for Registration</h1>
                </div>
                <div class="body">
                    <p>Dear ${formData.FirstName} ${formData.LastName},</p>
                    <p>You requested to complete the registration process on ${process.env.REACT_APP_OTP_PortalName}. Please use the following One Time Password (OTP) to proceed:</p>
                    <p class="otp-code">${generatedOtp}</p>
                    <p>Note: This OTP is valid for 10 minutes and can be used only once.</p>
                    <p>If you did not request this, please ignore this email or contact support if you feel this is an unauthorized attempt to access your account.</p>
                </div>
                <div class="footer">
                    <p>Best regards,</p>
                    <p>Your Team at ${process.env.REACT_APP_OTP_ClientName}</p>
                </div>
            </div>
        </body>
        </html>
        `;

      // Prepare FormData for the email API
      const formDataObj = new FormData();
      formDataObj.append("emailImportance", "normal");
      formDataObj.append(
        "recipients",
        JSON.stringify({
          to: [{ emailAddress: formData.Email }],
        })
      );
      formDataObj.append(
        "emailContents",
        JSON.stringify({
          subject: "Your OTP for Registration",
          HTML: htmlEmailTemplate,
        })
      );
      formDataObj.append("emailSender", "DoNotReply@aaneel.com");

      // Send the OTP email
      try {
        const response = await axios.post(
          process.env.REACT_APP_SEND_EMAIL_URL,
          formDataObj,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (response.status === 200) {
          setLoading(false);
          setAlertMessages([
            { type: "success", text: `OTP sent successfully` },
          ]);
        } else {
          setLoading(false);
          console.log("Failed to send OTP");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error sending OTP email:", error);
      }
    } catch (error) {
      // Handle the case where the email does not exist
      if (error.status === 204) {
        // Proceed with OTP generation if no email is found (email is available)
        console.log("Email is available for registration.");
      } else {
        setLoading(false);
        console.error("Error checking email existence:", error);
      }
    }
  };

  const validateOtp = (inputOtp) => {
    const currentTime = new Date();
    const otpGenerationTime = new Date(otpDetails.generatedTime);
    const isOtpValid = currentTime - otpGenerationTime < 10 * 60 * 1000;
    return isOtpValid && inputOtp === otpDetails.otp;
  };

  const handleNextClick = async (e) => {
    e.preventDefault();
    const supportNumber = process.env.REACT_APP_Support_CellNumber;
    let isValid = true;
    let newErrors = {};
    if (!formData.FirstName) {
      isValid = false;
      newErrors.FirstName = "First Name is required!";
    }
    if (!formData.LastName) {
      isValid = false;
      newErrors.LastName = "Last Name is required!";
    }
    if (!formData.DOB) {
      isValid = false;
      newErrors.DOB = "Date of Birth is required!";
    }
    if (!formData.PIN) {
      isValid = false;
      newErrors.PIN = "PIN is required!";
    }
    if (currentStep === 1) {
      if (isValid) {
        const identifier = formData.PIN;
        const patientRecord = await fetchPatientRecord(identifier);
        if (patientRecord.total > 0) {
          const patientDetails = patientRecord.entry[0].resource;
          const firstNameFromDb = patientDetails.name[0].given[0];
          const dbDOB = formatDate(patientDetails.birthDate);
          const inputDOB = InputformatDate(formData.DOB);
          if (
            patientDetails.name[0].family.toLowerCase() ===
              formData.LastName.toLowerCase() &&
            firstNameFromDb.toLowerCase() ===
              formData.FirstName.toLowerCase() &&
            dbDOB === inputDOB
          ) {
            goToNextStep();
            setAlertMessages([]);
          } else {
            setAlertMessages([
              {
                type: "error",
                text: `First Name, Last Name, or DOB does not match our records! Please call us at  ${supportNumber}`,
              },
            ]);
          }
        } else {
          setAlertMessages([
            {
              type: "error",
              text: `PIN Number does not match our records! Please call us at ${supportNumber}`,
            },
          ]);
          setErrors((prevState) => ({ ...prevState, ...newErrors }));
        }
      } else {
        setErrors(newErrors);
      }
    }
    if (currentStep === 2) {
      if (!enteredOtp) {
        isValid = false;
        newErrors.OTP = "OTP is required!";
      } else if (!validateOtp(enteredOtp)) {
        isValid = false;
        newErrors.OTP = "Invalid or expired OTP. Please try again.";
      }

      if (!formData.Email) {
        isValid = false;
        newErrors.Email = "Email is required!";
      }

      if (isValid) {
        setAlertMessages([]);
        goToNextStep();
      } else {
        setErrors(newErrors);
      }
    }
  };
  const [errors, setErrors] = useState({});
  const getCurrentDateTime = () => {
    return new Date().toISOString();
  };

  const insertMember = async (userData) => {
    try {
      const requestData = {
        SSOGuid: userData.ID,
        FirstName: userData.FirstName,
        LastName: userData.LastName,
        Username: userData.Username,
        Email: userData.Email,
        DOB: userData.DOB,
        PIN: userData.PIN,
        MemberID: userData.MemberID,
        RowStatus: "A",
        AddDate: getCurrentDateTime(),
        BB_IsActive: "No",
        GivenName: `${userData.FirstName}, ${userData.LastName}`,
      };
      const response = await PreviousInsuranceApi.createMember(requestData);

      if (response) {
        console.log("response from create user is=====>", response);
        console.log("request data for the create usre is=====>", requestData);
        setLoading(false);
        navigate("/");
      } else {
        setLoading(false);
        console.error("Failed to insert the member:", response.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error inserting the member:", error);
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "PIN") {
      value = value.replace(/\s/g, "");
    }
    const updatedData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedData);

    schema
      .validateAt(name, { ...formData, [name]: value })
      .then(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
        }));

        if (name === "Password" || name === "ConfirmPassword") {
          if (updatedData.Password !== updatedData.ConfirmPassword) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              ConfirmPassword: "Passwords must match",
            }));
          }
        }
      })
      .catch((err) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: err.message,
        }));
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAgreed) {
      setShowAgreementModal(true);
      return;
    }
    setLoading(true);
    try {
      await schema.validate(formData, { abortEarly: false });
      const { MemberID, ConfirmPassword, DOB, ...restOfData } = formData;
      const requestData = {
        ...restOfData,
        Clients: [`${process.env.REACT_APP_SSO_CLIENTS}`],
        EmailConfirmed: true,
        FullName: `${restOfData.FirstName}, ${restOfData.LastName}`,
        IsEnabled: true,
        Claims: [
          {
            ClaimType: "client_roles",
            ClaimValue: "globalReaderUser",
          },
          {
            ClaimType: "fhirUser",
            ClaimValue: `${memberUrl}/Patient/${newMemberId}`,
          },
        ],
      };

      const ssoResponse = await ssoInstance.post(
        "api/user/CreateApplicationUserWithPassword",
        requestData
      );

      if (ssoResponse.status === 200 && ssoResponse.data.succeeded) {
        await insertMember({ ...formData });
        navigate("/");
      } else if (ssoResponse.status === 200 && !ssoResponse.data.succeeded) {
        const errorMessagesString = ssoResponse.data.errors
          .map((error) => error.description)
          .join(", ");
        setAlertMessages([
          {
            type: "error",
            text: errorMessagesString,
          },
        ]);
      } else {
        throw new Error("SSO Registration failed.");
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      } else {
        const errorMessage =
          err.response?.data?.message ||
          err.message ||
          "An unknown error occurred.";
        setAlertMessages([
          {
            type: "error",
            text: { errorMessage },
          },
        ]);
      }
    } finally {
      setLoading(false);
    }
  };

  // function to get client name and fetch logo based on client name
  const clientName = process.env.REACT_APP_ClientName;
  const getLogoSource = () => {
    switch (clientName) {
      case "freedom":
        return `${freedomLogo}`;
      case "healthsun":
        return `${healthSunLogo}`;
      case "optimum":
        return `${optimumLogo}`;
      case "demo":
        return `${demoLogo}`;
      default:
        return "";
    }
  };

  const getBgSource = () => {
    switch (clientName) {
      case "freedom":
        return `${sliderbg}`;
      case "healthsun":
        return `${healthSunBg}`;
      case "optimum":
        return `${optimumBg}`;
      case "demo":
        return `${sliderbg}`;
      default:
        return "";
    }
  };
  const [hidden, setHidden] = React.useState(false);
  const handleLoginClick = () => {
    userManager.signinRedirect().catch((error) => {
      console.error("SSO login error:", error);
    });
  };
  const togglePassword = () => {
    setPasswordToggle(!passwordToggle);
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordToggle(!confirmPasswordToggle);
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center authentication-main">
        <div className="d-flex authentication-card">
          <div className="authentication-left">
            <img src={getBgSource()} alt="Slider" />
          </div>
          <div className="authentication-right d-flex space-between-center flex-column">
            <div className="h-full">
              <div className="authentication-header d-flex justify-content-sm-between align-items-sm-center flex-column flex-sm-row">
                <div className="d-flex flex-column authentication-title">
                  <h1>Sign up</h1>
                  <span>Insert your information to signup</span>
                </div>
                <div className="authentication-logo">
                  <img src={getLogoSource()} alt="Freedom Health" />
                </div>
              </div>
              <div className="stepper d-flex">
                <div
                  className={`align-items-sm-center align-items-start step d-flex ${
                    currentStep === 1
                      ? "active"
                      : currentStep > 1
                      ? "success"
                      : ""
                  }`}
                >
                  <span>
                    {currentStep > 1 ? <i className="fh_check_done"></i> : "1"}
                  </span>
                  <label>Personal Info</label>
                </div>
                <div
                  className={`align-items-sm-center align-items-start step d-flex ${
                    currentStep === 2
                      ? "active"
                      : currentStep > 2
                      ? "success"
                      : "disabled"
                  }`}
                >
                  <span>
                    {currentStep > 2 ? <i className="fh_check_done"></i> : "2"}
                  </span>
                  <label>Email Info</label>
                </div>
                <div
                  className={`align-items-sm-center align-items-start step d-flex ${
                    currentStep === 3 ? "active" : "disabled"
                  }`}
                >
                  <span>3</span>
                  <label>Credentials Info</label>
                </div>
              </div>
              <div className="form-content">
                {loading && (
                  <div className="text-center my-4">
                    <div className="spinner-border" role="status"></div>
                  </div>
                )}
                {alertMessages.map((message, index) => (
                  <div
                    key={index}
                    className={`alert ${
                      message.type === "success"
                        ? "alert-success"
                        : "alert-danger"
                    }`}
                    role="alert"
                  >
                    {message.text}
                  </div>
                ))}

                <form
                  onSubmit={handleSubmit}
                  className="needs-validation"
                  noValidate
                >
                  {currentStep === 1 && (
                    <div className="row">
                      <div className="col-12">
                        <label
                          htmlFor="FirstName"
                          className="d-flex form-label"
                        >
                          First Name <span>*</span>
                        </label>
                        <div className="form-field position-relative">
                          <div className="position-relative">
                            <input
                              type="text"
                              className={`form-control h-48 ${
                                errors.FirstName
                                  ? "is-invalid"
                                  : formData.FirstName
                                  ? "is-valid"
                                  : ""
                              }`}
                              id="FirstName"
                              name="FirstName"
                              value={formData.FirstName}
                              onChange={handleChange}
                              required
                            />
                            <span className="field-icon position-absolute top-50 translate-middle-y">
                              <i className="fh_user_fill"></i>
                            </span>
                          </div>
                          <div
                            className={`invalid-feedback ${
                              errors.FirstName ? "invalid-error" : ""
                            }`}
                          >
                            {errors.FirstName}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="LastName" className="d-flex form-label">
                          Last Name <span>*</span>
                        </label>
                        <div className="form-field position-relative">
                          <div className="position-relative">
                            <input
                              type="text"
                              className={`form-control h-48 ${
                                errors.LastName
                                  ? "is-invalid"
                                  : formData.LastName
                                  ? "is-valid"
                                  : ""
                              }`}
                              id="LastName"
                              name="LastName"
                              value={formData.LastName}
                              onChange={handleChange}
                              required
                            />
                            <span className="field-icon position-absolute top-50 translate-middle-y">
                              <i className="fh_user_fill"></i>
                            </span>
                          </div>
                          <div
                            className={`invalid-feedback ${
                              errors.LastName ? "invalid-error" : ""
                            }`}
                          >
                            {errors.LastName}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row">
                          <div className="col-sm-6 col-12">
                            <label htmlFor="DOB" className="d-flex form-label">
                              Date of Birth <span>*</span>
                            </label>
                            <div
                              className={`form-field ${
                                errors.DOB
                                  ? "is-invalid"
                                  : formData.DOB
                                  ? "is-valid"
                                  : ""
                              }`}
                            >
                              <DatePicker
                                className={`form-control h-48 ${
                                  errors.DOB
                                    ? "is-invalid"
                                    : formData.DOB
                                    ? "is-valid"
                                    : ""
                                }`}
                                placeholderText="MM / DD / YYYY"
                                selected={formData.DOB}
                                onChange={(date) =>
                                  handleChange({
                                    target: { value: date, name: "DOB" },
                                  })
                                }
                                maxDate={new Date()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="MM / dd / yyyy"
                              />
                              <div
                                className={`invalid-feedback ${
                                  errors.DOB ? "invalid-error" : ""
                                }`}
                              >
                                {errors.DOB}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-12 position-relative">
                            <div
                              className={`member-id-card ${
                                hidden ? "element-visible" : "element-hidden"
                              }`}
                            >
                              <div className="d-flex justify-content-between member-id-header">
                                <p>
                                  <span className="red">
                                    <b>*</b>
                                  </span>{" "}
                                  For PIN, please enter{" "}
                                  <b>
                                    <span className="pin-info-color">
                                      full Member ID Number
                                    </span>{" "}
                                  </b>{" "}
                                  on your insurance card and{" "}
                                  <b>
                                    <span className="red">
                                      last 4 digits of Medicare ID Number
                                    </span>{" "}
                                  </b>{" "}
                                  on your Medicare card.
                                  <br />
                                  Eg., For <b> Member ID</b>{" "}
                                  <b>
                                    <span className="pin-info-color">
                                      P1234567801
                                    </span>{" "}
                                  </b>{" "}
                                  and <b>Medicare ID</b>{" "}
                                  <b>
                                    <span className="red">TEG4-TE5-MK74</span>
                                  </b>
                                  , PIN would be{" "}
                                  <b>
                                    <span className="pin-info-color">
                                      P1234567801
                                    </span>
                                  </b>
                                  <b>
                                    <span className="red">MK74</span>
                                  </b>
                                </p>
                                <span onClick={() => setHidden(!hidden)}>
                                  <i className="fh_close"></i>
                                </span>
                              </div>
                              <div className="member-id-img">
                                <img src={memberIDCard} alt="Member ID Card" />
                              </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center form-label-main">
                              <label
                                htmlFor="PIN"
                                className="d-flex form-label"
                              >
                                PIN <span>*</span>
                              </label>
                              <span
                                className="information-icon"
                                onClick={() => setHidden(!hidden)}
                              >
                                <u>Add a PIN?</u>{" "}
                                <i className="fh_error_line"></i>
                              </span>
                            </div>
                            <div className="form-field position-relative">
                              <input
                                type="text"
                                className={`form-control h-48 ${
                                  errors.PIN
                                    ? "is-invalid"
                                    : formData.PIN
                                    ? "is-valid"
                                    : ""
                                }`}
                                id="PIN"
                                name="PIN"
                                value={formData.PIN}
                                onChange={handleChange}
                                onClick={() => setHidden(!hidden)}
                                required
                              />
                              <div
                                className={`invalid-feedback ${
                                  errors.PIN ? "invalid-error" : ""
                                }`}
                              >
                                {errors.PIN}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 form-action">
                        <div className="row">
                          <div className="col-sm-6 col-12">
                            <button
                              type="button"
                              className="btn btn-border-primary w-100 h-48"
                              onClick={handleLoginClick}
                            >
                              <i className="fh_arrow_left icon-mr"></i> Back to
                              login
                            </button>
                          </div>
                          <div className="col-sm-6 col-12">
                            <button
                              type="submit"
                              className="btn btn-primary w-100 h-48"
                              onClick={handleNextClick}
                            >
                              Next <i className="fh_arrow_right icon-ml"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentStep === 2 && (
                    <>
                      {/* // New Step 2: Additional Info */}
                      {/* // [Include form fields for additional information such as phone number] */}
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="Email" className="d-flex form-label">
                            E-Mail Address <span>*</span>
                          </label>
                          <div className="form-field position-relative">
                            <div className="position-relative">
                              <div className="input-group">
                                <input
                                  type="email"
                                  className={`form-control h-48 ${
                                    errors.Email
                                      ? "is-invalid"
                                      : formData.Email
                                      ? "is-valid"
                                      : ""
                                  }`}
                                  id="Email"
                                  name="Email"
                                  value={formData.Email}
                                  onChange={handleChange}
                                  required
                                />

                                <button
                                  className="btn btn-border-primary"
                                  type="button"
                                  id="btnSendOtp"
                                  onClick={handleSendOtp}
                                >
                                  Send OTP
                                </button>
                              </div>
                            </div>
                            <div
                              className={`invalid-feedback ${
                                errors.Email ? "invalid-error" : ""
                              }`}
                            >
                              {errors.Email}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="Username"
                            className="d-flex form-label"
                          >
                            Enter OTP <span>*</span>
                          </label>
                          <div className="form-field position-relative">
                            <div className="position-relative">
                              <input
                                type="text"
                                className={`form-control h-48 ${
                                  errors.OTP
                                    ? "is-invalid"
                                    : enteredOtp
                                    ? "is-valid"
                                    : ""
                                }`}
                                id="OTP"
                                name="OTP"
                                value={enteredOtp} // Use enteredOtp state
                                onChange={(e) => setEnteredOtp(e.target.value)} // Update to set enteredOtp
                                required
                              />

                              <span className="field-icon position-absolute top-50 translate-middle-y">
                                <i className="fh_sms_popup"></i>
                              </span>
                            </div>
                            <div
                              className={`invalid-feedback ${
                                errors.OTP ? "invalid-error" : ""
                              }`}
                            >
                              {errors.OTP}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 form-action">
                          <div className="row">
                            <div className="col-sm-6 col-12">
                              <button
                                type="button"
                                className="btn btn-border-primary w-100 h-48"
                                onClick={goToPreviousStep}
                              >
                                <i className="fh_arrow_left icon-mr"></i> Back
                              </button>
                            </div>
                            <div className="col-sm-6 col-12">
                              <button
                                type="submit"
                                className="btn btn-primary w-100 h-48"
                                onClick={handleNextClick}
                              >
                                Next <i className="fh_arrow_right icon-ml"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {currentStep === 3 && (
                    <div className="row">
                      <div className="col-12">
                        <label htmlFor="Username" className="d-flex form-label">
                          Username <span>*</span>
                        </label>
                        <div className="form-field position-relative">
                          <div className="position-relative">
                            <input
                              type="text"
                              className={`form-control h-48 ${
                                errors.Username
                                  ? "is-invalid"
                                  : formData.Username
                                  ? "is-valid"
                                  : ""
                              }`}
                              id="Username"
                              name="Username"
                              value={formData.Username}
                              onChange={handleChange}
                              required
                            />

                            <span className="field-icon position-absolute top-50 translate-middle-y">
                              <i className="fh_user_fill"></i>
                            </span>
                          </div>
                          <div
                            className={`invalid-feedback ${
                              errors.Username ? "invalid-error" : ""
                            }`}
                          >
                            {errors.Username}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-sm-6 col-12">
                            <label
                              htmlFor="Password"
                              className="d-flex form-label"
                            >
                              Password <span>*</span>
                            </label>
                            <div className="form-field position-relative">
                              <div className="position-relative">
                                <input
                                  type={passwordToggle ? "text" : "password"}
                                  className={`form-control h-48 ${
                                    errors.Password
                                      ? "is-invalid"
                                      : formData.Password
                                      ? "is-valid"
                                      : ""
                                  }`}
                                  id="Password"
                                  name="Password"
                                  placeholder=""
                                  value={formData.Password}
                                  onChange={handleChange}
                                  required
                                />
                                <span
                                  className="field-icon position-absolute top-50 translate-middle-y"
                                  onClick={togglePassword}
                                >
                                  <i
                                    className={
                                      passwordToggle
                                        ? "fh_eye_off_line"
                                        : "fh_eye_fill"
                                    }
                                  ></i>
                                </span>
                              </div>
                              <div
                                className={`invalid-feedback ${
                                  errors.Password ? "invalid-error" : ""
                                }`}
                              >
                                {errors.Password}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <label
                              htmlFor="ConfirmPassword"
                              className="d-flex form-label"
                            >
                              Confirm Password <span>*</span>
                            </label>
                            <div className="form-field position-relative">
                              <div className="position-relative">
                                <input
                                  type={
                                    confirmPasswordToggle ? "text" : "password"
                                  }
                                  className={`form-control h-48 ${
                                    errors.ConfirmPassword
                                      ? "is-invalid"
                                      : formData.ConfirmPassword
                                      ? "is-valid"
                                      : ""
                                  }`}
                                  id="ConfirmPassword"
                                  name="ConfirmPassword"
                                  value={formData.ConfirmPassword}
                                  onChange={handleChange}
                                  required
                                />
                                <span
                                  className="field-icon position-absolute top-50 translate-middle-y"
                                  onClick={toggleConfirmPassword}
                                >
                                  <i
                                    className={
                                      confirmPasswordToggle
                                        ? "fh_eye_off_line"
                                        : "fh_eye_fill"
                                    }
                                  ></i>
                                </span>
                              </div>
                              <div
                                className={`invalid-feedback ${
                                  errors.ConfirmPassword ? "invalid-error" : ""
                                }`}
                              >
                                {errors.ConfirmPassword}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 form-action">
                        <div className="row">
                          <div className="col-sm-12 col-12 d-flex justify-content-start align-items-start">
                            <input
                              type="checkbox"
                              className="form-check-input me-3 flex-shrink-0 pointer"
                              id="termsCheckbox"
                              checked={isAgreed}
                              onChange={(e) => setIsAgreed(e.target.checked)}
                            />
                            <label
                              className="form-check-label mt-1"
                              htmlFor="termsCheckbox"
                            >
                              I have read and agree to the{" "}
                              <a
                                href="https://www.aaneel.com/application-privacy-policy"
                                target="_blank"
                                className="primary"
                                rel="noopener noreferrer"
                              >
                                Privacy Policy
                              </a>{" "}
                              &{" "}
                              <a
                                href="https://www.aaneel.com/application-terms-of-service"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="primary"
                              >
                                Terms of Service
                              </a>
                              .
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 form-action">
                        <div className="row">
                          <div className="col-sm-6 col-12">
                            <button
                              type="button"
                              className="btn btn-border-primary w-100 h-48"
                              onClick={goToPreviousStep}
                            >
                              <i className="fh_arrow_left icon-mr"></i> Back
                            </button>
                          </div>
                          <div className="col-sm-6 col-12">
                            <button
                              type="submit"
                              className="btn btn-primary w-100 h-48"
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div className="authentication-footer d-flex flex-column flex-sm-row justify-content-between align-items-sm-end">
              <p>© 2023 AaNeel Infotech LLC</p>
              <span className="poweredby-image">
                <img src={poweredby} alt="AaNeel PoweredBy" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal ${showAgreementModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showAgreementModal ? "block" : "none" }}
      >
        <div className="modal-dialog signup-me-popup" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Agreement Required</h5>
            </div>
            <div className="modal-body">
              <p>
                Please agree to the Privacy Policy and Terms of Service to
                continue.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setShowAgreementModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpForm;
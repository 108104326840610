import React, { useState, useEffect, useCallback } from "react";
import { UserManager } from "oidc-client";
import { hl7Instance, dbInstance } from "../../api/axios";
import oidcConfig from "../../config/oidc-config";
import "./Labresult.scss";
import { useAuth } from "../../context/auth-context";
import PerfectScrollbar from "react-perfect-scrollbar";
import qs from "qs";
import {
  toTitleCase,
  formatDateAndTime,
} from "../../utils/Utils";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    throw error;
  }
}
const fetchPatientData = async () => {
  try {
    let accessToken;
    let ImpersonateUserID = localStorage.getItem("ImpersonateUserID");
    let userSub = "";
    const PortalToken = localStorage.getItem("PortalAdmin-AccessToken");
    if (ImpersonateUserID) {
      userSub = ImpersonateUserID;
      accessToken = PortalToken;
    } else {
      accessToken = await getAccessToken();
      const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
      userSub = tokenPayload.sub;
    }
  } catch (error) {
  }
};
function LabResult() {
  const [searchQuery, setSearchQuery] = useState("");
  const [labs, setLabs] = useState([]);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrls, setPrevUrls] = useState([]);
  const [error, setError] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);
  const { PatientIDHL7, setPatientIDHL7 } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const[loading,setLoading]=useState(false)
  const [paginationUrls, setPaginationUrls] = useState({
    current: "",
    next: "",
    prev: "",
  });
  const initialUrl = ""; // Define your initial URL here
  const [urlStack, setUrlStack] = useState([initialUrl]);
  //const prevPageRef = useRef();

  const handleSearch = useCallback(async () => {
    setLoading(true)
    try {
      if (!PatientIDHL7) {
        const newPatientIDHL7 = fetchPatientData();
        setPatientIDHL7(newPatientIDHL7);
      }
      //  console.log("provider id from auth-context" + PatientIDHL7);
      setError(null);
      const accessToken = await getAccessToken();

      // Conditionally constructing the endpoint based on searchQuery
      // let endpoint = `/DiagnosticReport?subject=${PatientIDHL7}&_include=DiagnosticReport:result`;
      //?TestCode:contains=Specific Gravity
      let endpoint = `/DiagnosticReport?_sort=-date&subject=${PatientIDHL7}`;

      if (searchQuery.trim() !== "") {
        // Split the search query and URL encode each part
        const testCodeQueryParts = searchQuery
          .trim()
          .split(/\s+/)
          .map(
            (code, index) =>
              `TestCode${index > 0 ? ":contains" : ""}=${encodeURIComponent(
                code
              )}`
          );

        // Join the query parts with '&' and append to the endpoint
        const testCodeQueryString = testCodeQueryParts.join("&");
        endpoint += `&${testCodeQueryString}`;
      }

      const response = await hl7Instance.get(endpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;

      setLabs(response.data.entry || []);
      setTotalRecords(response.data.total || 0);
      const computedPage = Math.floor(((data.entry?.length || 0) - 1) / 10) + 1;
      setCurrentPage(computedPage);

      const nextLink = data.link.find((l) => l.relation === "next");
      const baseURL = hl7Instance.defaults.baseURL || "";
      const queryString = qs.stringify(searchQuery);
      const fullURL =
        `${baseURL}${endpoint}` + (queryString ? `?${queryString}` : "");
      setUrlStack([fullURL]);
      if (nextLink) {
        //console.log(`fullurl provider from next link: ${fullURL}`);
        // console.log(`nextLink provider from next link: ${nextLink.url}`);

        setPaginationUrls({
          current: fullURL,
          next: nextLink.url,
          prev: "",
        });

        setPrevUrls(fullURL);
        setNextUrl(nextLink.url);
      }
      setLoading(false)
    } catch (error) {
      console.error("error in fetching lab result=====>",error)
    }finally{
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PatientIDHL7, searchQuery]);

  // Rest of the fetchNextPage and fetchPreviousPage functions remain largely the same

  useEffect(() => {
    handleSearch();
  }, []);

  const fetchNextPage = async () => {
    setLoading(true)
    try {
      setError(null);
      const accessToken = await getAccessToken();

      const response = await hl7Instance.get(paginationUrls.next, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");

      setUrlStack((prevUrls) => [...prevUrls, paginationUrls.current]); // push the current URL

      setPaginationUrls({
        current: paginationUrls.next,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 1] || "", // Set the previous URL
      });

      setLabs(response.data.entry || []);
      setCurrentPage((prevPage) => prevPage + 1);
      if (totalRecords === null) {
        setTotalRecords(data.total || 0);
      }
      setLoading(false)
    } catch (error) {
      setError(
        "An error occurred while fetching the next page of lab results. Please try again."
      );
    }
  };

  const fetchPreviousPage = async () => {
    setLoading(true);
    try {
      setError(null);
      if (urlStack.length <= 1) return; // Do not fetch if there's no previous URL in the stack

      // Get the previous URL from the stack
      const prevUrl = urlStack[urlStack.length - 1];

      const accessToken = await getAccessToken();
      const response = await hl7Instance.get(prevUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");

      setUrlStack((prevUrls) => {
        // Remove the last URL
        const newUrls = [...prevUrls];
        newUrls.pop();
        return newUrls;
      });

      setPaginationUrls({
        current: prevUrl,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 2] || "", // Set the new previous URL
      });

      setLabs(response.data.entry || []);
      setCurrentPage((prevPage) => prevPage - 1);
      if (totalRecords === null) {
        setTotalRecords(data.total || 0);
      }
    } catch (error) {
      setError(
        "An error occurred while fetching the previous page. Please try again."
      );
    } finally {
      setLoading(false)
    }
  };

  const handleClearForm = () => {
    setSearchQuery("");
    handleSearch();
  };

  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(totalRecords / ITEMS_PER_PAGE);

  return (
    <>
      <div className="main-content">
        <div className="content-header">
          <h1 className="page-header">Lab Directory</h1>
        </div>
        <div className="card">
          <div className="card-body">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="card-form form-content mt-0">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
                  <label htmlFor="SearchLab" className="d-flex form-label">
                    Search Lab Result
                  </label>

                  <div className="form-field position-relative">
                    <input
                      id="SearchLab"
                      type="text"
                      className="form-control"
                      placeholder="Search for a lab result"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="field-icon position-absolute top-50 translate-middle-y">
                      <i className="fh_search"></i>
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
                  <button
                    type="button"
                    className="btn btn-primary h-48 me-3"
                    onClick={handleSearch}
                  >
                    <span>Search</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-border-primary h-48"
                    onClick={handleClearForm}
                  >
                    <span>Clear</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-content">
              <div className="content-subheader d-flex align-items-center">
                <h2 className="page-subheader me-3">Lab Result List</h2>
                <span className="total-record">
                  [ Total Lab Result: {totalRecords} ]
                </span>
              </div>
              <PerfectScrollbar className="table-responsive custom-main-table">
                <table className="table">
                  <thead className="sticky-top">
                    <tr>
                      {/* <th className='w-25'>ID</th> */}
                      <th>Test Name</th>
                      {/* <th>Date</th> */}
                      <th>Lab Name</th>
                      <th>Physician</th>
                      <th>Effective Date</th>
                      <th>Observation</th>
                      <th>Observation Value</th>
                      <th>Value Range</th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                    // Show loading spinner when isLoading is true
                    <tr>
                      <td colSpan={7} className="text-center my-4">
                        <div className="spinner-border" role="status"></div>
                      </td>
                    </tr>
                  ):labs && labs.length === 0 ? (
                    // Show no data found message if gridData is empty
                    <tr>
                      <td colSpan={10} className="text-center">
                        No data found
                      </td>
                    </tr>
                  ) :
                    labs?.map((entry) => {
                      // Extracting additional data for display
                      const practitionerName =
                        entry.resource.performer?.[0]?.display || "";

                      const observationData =
                        entry.resource.contained?.[0] || {};
                      const observationCodeDisplay =
                        observationData.code?.coding[0]?.display || "N/A";

                      const lowValue =
                        observationData.referenceRange?.[0]?.low?.value ||
                        "N/A";
                      const highValue =
                        observationData.referenceRange?.[0]?.high?.value ||
                        "N/A";
                      const EffectiveDate =
                        entry.resource.effectiveDateTime ||
                        "N/A";
                      // const observationStatus =
                      //   entry.resource.contained?.[0].status || "";
                      const observationValue =
                        observationData.valueCodeableConcept?.text || "N/A";
                      const valueQuantity =
                        observationData.valueQuantity?.value;
                      const displayValue =
                        valueQuantity !== undefined && valueQuantity !== null
                          ? valueQuantity
                          : observationValue;
                      const encounterDisplay =
                        entry.resource.encounter?.display || "N/A";
                      return (
                        <tr key={entry.resource.id}>
                          {/* <td className='w-25'>
                            <Link to={`/Lab/${entry.resource.id}`}>
                              {entry.resource.id}
                            </Link>
                          </td> */}
                          <td>
                            {/* <Link to={`/Lab/${entry.resource.id}`}> */}
                            {toTitleCase(
                              entry.resource.code?.coding[0]?.display || "N/A"
                            )}
                            {/* </Link> */}
                          </td>
                          {/* <td>{formatDate(entry.resource.issued)}</td> */}
                          <td>{toTitleCase(practitionerName)}</td>
                          <td>{encounterDisplay}</td>
                          <td>{formatDateAndTime(EffectiveDate)}</td>
                          <td>{observationCodeDisplay}</td>
                          <td>{displayValue}</td>
                          <td>{`${lowValue} - ${highValue}`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </PerfectScrollbar>
              <div className="d-flex justify-content-end">
                <nav
                  aria-label="Page navigation"
                  className="d-flex align-items-center"
                >
                  {labs.lenght>0 &&(
                    <>
                     <span className="pagnination-info me-2">Page</span>
                  <ul className="pagination d-flex mb-0">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={fetchPreviousPage}
                          disabled={currentPage === 1}
                        >
                          <i className="fh_arrow_left_line"></i>
                        </button>
                      </li>
                    )}
                    <li className="page-item">
                      <button className="page-link current-page">
                        {currentPage}
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link me-0"
                        onClick={fetchNextPage}
                        disabled={currentPage >= totalPages}
                      >
                        <i className="fh_arrow_right_line"></i>
                      </button>
                    </li>
                  </ul>
                    </>
                  )}
                 
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LabResult;

import {
  createSlice,
  createAsyncThunk,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { PreviousInsuranceApi } from "../../api/services/PrevInsuranceService";

const initialState = {
  isLoading: false,
  error: null as string | null,
  dropdownListData: null,
  payerInformation:null,
  payerConfiguration:null,
  gridData:null,
  payerTokenDetails:null,
  fhirUrl:null
};
export const getDropDownListData = createAsyncThunk(
  "patient/getDropDownList",
  async () => {
    try {
      const response = await PreviousInsuranceApi.getPayersForDropDownData();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPayerInformationbyID = createAsyncThunk(
  "patient/getPayerInformationbyID",
  async (id: number) => {
    try {
      const response = await PreviousInsuranceApi.getPayerbyId(id);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getPayerConfiguration = createAsyncThunk(
  "patient/getPayerConfiguration",
  async (payerId: number) => {
    try {
      const response = await PreviousInsuranceApi.getPayerConfiguration(payerId);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Helper function to clean the payload by removing null or undefined values
const cleanPayload = (data: object) => {
  return Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value !== null && value !== undefined)
  );
};

export const updatePayerInformation = createAsyncThunk(
  "patient/updatePayerInformation",
  async (data: object) => {
    try {
      // Clean the payload before sending it to the API
      const cleanedData = cleanPayload(data);
      
      const response = await PreviousInsuranceApi.updatePayerInformation(cleanedData);
      console.log("response of update API is=====>", response);
      return response;
    } catch (error) {
      console.error("Error in updatePayerInformation thunk:", error);
      throw error; // Ensure the error is thrown to trigger rejection
    }
  }
);
export const fetchGridData = createAsyncThunk(
  'previousInsurance/fetchGridData',
  async ({payerName, SSOUserID, page, pageSize, sortColumn, sortOrder }:any, thunkAPI) => {
    try {
      const response = await PreviousInsuranceApi.getGridData(payerName,SSOUserID, page, pageSize, sortColumn, sortOrder);
      return response;
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getRefreshToken = createAsyncThunk(
  "patient/getRefreshToken",
  async () => {
    try {
      const response = await PreviousInsuranceApi.getRefreshToken();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getFhirURI = createAsyncThunk(
  "patient/getFhirURI",
  async (payerId: number) => {
    try {
      const response = await PreviousInsuranceApi.gerFhirURI(payerId);
      return response;
    } catch (error) {
      return error;
    }
  }
);
const PrevInsuranceSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    RESET_STATE: () => initialState,
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDropDownListData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dropdownListData = action.payload;
      })
      .addCase(getPayerInformationbyID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.payerInformation = action.payload;
      })
      .addCase(getPayerConfiguration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.payerConfiguration = action.payload;
      })
      .addCase(fetchGridData.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.gridData = action.payload
      })
      .addCase(getRefreshToken.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.payerTokenDetails=action.payload
      })
      .addCase(getFhirURI.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.fhirUrl=action.payload
      })
      .addMatcher(isPending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? null;
      
      });
  },
});
export default PrevInsuranceSlice.reducer;
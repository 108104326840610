import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import CustomLoader from "../custom-loader/CustomLoader";

function AuthCallback() {
  const { userManager, setIsLoading } = useAuth();
  const navigate = useNavigate(); // Make sure to import useNavigate

  useEffect(() => {
    const handleSignIn = async () => {
      try {
        setIsLoading(true); // Set loading state
        const user = await userManager.signinRedirectCallback();

        //const user = await userManager.signinSilent();
        const access_token = user.access_token;
        // localStorage.removeItem("Portal-AccessToken");
        // localStorage.setItem("Portal-AccessToken", access_token);
        console.log(access_token);
        const returnPath =
          user && user.state && user.state.returnPath
            ? user.state.returnPath
            : "/";
        setIsLoading(false);
        // navigate(returnPath); // Set isLoading to false if user exists
        const preAuthURL = localStorage.getItem("preAuthURL");
        if (preAuthURL) {
          const ImpersonateUserID = localStorage.getItem("ImpersonateUserID");
          //localStorage.removeItem("preAuthURL"); // Clear the saved URL

          const url = new URL(preAuthURL);
          const urlSearchParams = new URLSearchParams(url.search);

          // Check if you need to replace the 'ImpersonateUserID' in the URL
          if (ImpersonateUserID) {
            urlSearchParams.set("code", ImpersonateUserID); // Replace or set the 'code' parameter
            // localStorage.removeItem("ImpersonateUserID");
          }

          const updatedSearch = urlSearchParams.toString();
          const updatedURL = `${url.pathname}?${updatedSearch}`;
          console.log("Updated URL", updatedURL);
          navigate(updatedURL);
          // navigate(returnPath);
        } else {
          const returnPath = user?.state?.returnPath || "/";
          navigate(returnPath);
        }
      } catch (error) {
        // console.log(error.response);
        if (error && error.message === "invalid_grant") {
          // navigate("/login");
        } else {
          localStorage.removeItem("Portal-AccessToken");
          console.error("Error during sign-in callback:", error);
        }

        // navigate("/error");
      }
    };
    handleSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userManager, navigate]);

  return <CustomLoader />;
}

export default AuthCallback;

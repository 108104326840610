import { generateUniqueId } from "../utils/Utils";

const blueButtonOAuthConfig = {
  authorizationEndpoint: process.env.REACT_APP_BLUEBUTTON_OIDC_AUTHORITY,
  tokenEndpoint: process.env.REACT_APP_BLUEBUTTON_TOKENENDPOINT,
  clientId: process.env.REACT_APP_BLUEBUTTON_OIDC_CLIENTID,
  clientSecret: process.env.REACT_APP_BLUEBUTTON_OIDC_CLIENTSECRET,
  redirectUri: process.env.REACT_APP_BLUEBUTTON_OIDC_REDIRECTURI,
  responseType: "code",
  scope: process.env.REACT_APP_BLUEBUTTON_OIDC_SCOPE,
};

// Function to redirect the user to the authorization endpoint
function redirectToAuthorizationEndpoint() {
  const { authorizationEndpoint, responseType, clientId, redirectUri } =blueButtonOAuthConfig;
  const uniqueId = generateUniqueId();
  const url =
    `${authorizationEndpoint}?` +
    `response_type=${responseType}&` +
    `client_id=${clientId}&` +
    `redirect_uri=${encodeURIComponent(redirectUri)}&`+
    `state=${uniqueId}`;

  window.location.href = url;
}

// Function to retrieve the 'code' parameter from the URL query string
function getCodeFromCallbackURL() {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get("code");
}

// Function to exchange the authorization code for an access token
async function fetchToken(code) {
  const { tokenEndpoint, clientId, clientSecret, redirectUri } =
    blueButtonOAuthConfig;

  const credentials = window.btoa(`${clientId}:${clientSecret}`);
  console.log(`Base64: ${credentials}`);
  console.log(`authcode : ${code}`);
  const data = {
    code: code,
    grant_type: "authorization_code",
    redirect_uri: redirectUri,
  };
  
  try {
    const response = await fetch(tokenEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${credentials}`,
      },
      body: new URLSearchParams(data).toString(),
    });

    const responseData = await response.json();
    console.log(`response data: ${JSON.stringify(responseData)}`);

    if (responseData.access_token) {
      localStorage.removeItem("B_RefreshToken");
      localStorage.removeItem("B_patientId");
      localStorage.removeItem("BB_NewAccess_Token");
      
      // Store the access token securely
      localStorage.setItem("B_RefreshToken", responseData.refresh_token);
      localStorage.setItem("B_patientId", responseData.patient);
      localStorage.setItem("BB_NewAccess_Token", responseData.access_token);
      return responseData;
    } else {
      console.error("Error1 fetching token", responseData);
    }
  } catch (error) {
    console.error("Error2 when fetching the token:", error);
  }
}

export {
  blueButtonOAuthConfig,
  fetchToken,
  redirectToAuthorizationEndpoint,
  getCodeFromCallbackURL,
};
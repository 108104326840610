import { configureStore } from '@reduxjs/toolkit';
import previousInsuranceReducer from './slice/PrevInsuranceSlice';

export const store = configureStore({
  reducer: {
    insuranceData: previousInsuranceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
